<template>
  <el-dialog
    :value="value"
    top="2vh"
    width="80%"
    :title="'对话详情' + (rowJson.read ? '-已读' : '-未读')"
    :show-close="false"
    :destroy-on-close="true"
  >
    <!-- 1.主体 -->
    <div class="flex-nowrap-start height-min">
      <!-- 1.1左边列表 -->
      <div class="mr-15" style="width: 75%">
        <div class="telAbox">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span class="headerTelA">违规主叫</span>
            </div>
            <div class="flex1">
              <div class="flex1Text1">
                <div>
                  <el-tag size="mini">主叫</el-tag> {{ telADesc }}
                  <el-button
                    class="mr-20"
                    type="danger"
                    size="mini"
                    v-if="!rowJson.telADisabled && isAdminUser == 'system'"
                    @click="bannedUserDialog = true"
                    >禁用
                  </el-button>
                  <el-tag
                    v-if="rowJson.telADisabled"
                    class="mr-10"
                    type="danger"
                    >已禁用</el-tag
                  >
                </div>
                <div style="margin-left: 45px">{{ rowJson.telAArea }}</div>
              </div>
              <div class="flex1Text2">
                <div>{{ infractions }}</div>
                <div>历史违规</div>
              </div>
            </div>
          </el-card>
        </div>
        <div style="margin-top: 10px">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <span class="headerTelA">违规通话</span>
            </div>
            <div class="text item">
              <div class="flex1">
                <el-select
                  v-model="voiceForm.type"
                  placeholder="请选择"
                  size="small"
                  @change="changeType"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model="voiceForm.translatedContent"
                  placeholder="请输入任意关键词查询"
                  size="small"
                  class="width-100"
                  @input="changeInput"
                ></el-input>
              </div>
            </div>
            <div class="numBox" @scroll="handleScroll" ref="numBox">
              <div class="container">
                <div
                  @click="handleClick(item, index)"
                  class="row flex-spb"
                  :class="activeIndex == item.id ? 'active' : ' '"
                  v-for="(item, index) in voiceList"
                  :index="index"
                >
                  <div style="width: 60%">
                    <div>
                      <el-tag size="mini">被叫</el-tag>{{ item.telB }}
                      <el-button
                        class="mr-10"
                        type="danger"
                        size="mini"
                        v-if="!item.telBDisabled && isAdminUser == 'system'"
                        @click="bannedUserOther(item, index)"
                        >禁用
                      </el-button>
                      <el-tag
                        v-if="item.telBDisabled"
                        class="mr-10"
                        type="danger"
                        >已禁用</el-tag
                      >
                      <div>{{ item.telBAttr }}</div>
                    </div>
                    <div style="margin-top: 5px">
                      <el-tag size="mini">小号</el-tag>{{ item.telX }}
                      <el-button
                        class="mr-10"
                        type="danger"
                        size="mini"
                        v-if="!item.telXDisabled && isAdminUser == 'system'"
                        @click="bannedUserOther3(item, index)"
                        >禁用
                      </el-button>
                      <el-tag
                        v-if="item.telXDisabled"
                        class="mr-10"
                        type="danger"
                        >已禁用</el-tag
                      >
                    </div>
                     <div style="margin-top: 5px">
                      {{ item.stratTime }}
                    </div>
                  </div>

                  <div style="text-align: right; width: 40%">
                    <div >
                      <el-tag v-if="item.risk == 0" size="mini">无违规</el-tag>
                      <el-tag
                        v-else-if="item.risk == 1"
                        size="mini"
                        type="danger"
                        >特大违规</el-tag
                      >
                      <el-tag
                        v-else-if="item.risk == 2"
                        size="mini"
                        type="warning"
                        >重大违规</el-tag
                      >
                      <el-tag v-else-if="item.risk == 3" size="mini" type="info"
                        >一般违规</el-tag
                      >
                      <el-tag
                        v-else-if="item.risk == 4"
                        size="mini"
                        type="success"
                        >轻微违规</el-tag
                      >
                    </div>
                    <div style=" " class="secondBox">
                      <div ></div>
                      <label
            style="
             margin-top: 10px;
              
              padding-left: 5px;
              padding-right: 5px;
              padding-top: 2px;
              padding-bottom: 2px;
            "
            :class="auditStatusClass1(item.auditStatus)"
            >{{ auditStatusDesc1(item.auditStatus) }}</label
          >
                    
                    </div>
                   <div style="margin-top: 30px">
                      {{ item.duration ? item.duration : "0" }}秒
                   </div>
                  </div>
                </div>
                <i
                  class="el-icon-loading"
                  style="margin-left: 48%; font-size: 30px"
                  v-if="loadingIcon"
                ></i>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <!-- 中间 -->
      <div class="mr-15" style="width: 75%">
        <div>
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <span class="headerTelA">企业信息</span>
            </div>
            <div style="margin-top: 5px">渠道：{{ rowJson.nickname }}</div>
            <div>
              当前企业：{{ feigeCompany.company }}
              <el-tag size="mini" v-if="industryName" type="success">{{
                industryName
              }}</el-tag>
            </div>
            <div>创建时间：{{ feigeCompany.createTime }}</div>
            <div>
              企业违规主叫：<span style="color: red">{{
                feigeCompany.vcrTotal ? feigeCompany.vcrTotal : "0"
              }}</span
              >/{{ feigeCompany.total ? feigeCompany.total : "0" }}
            </div>
          </el-card>
        </div>
        <div style="margin-top: 10px">
          <el-card class="box-card" shadow="hover" style="height: 546px">
            <div slot="header" class="clearfix">
              <span class="headerTelA">质检详情</span>
            </div>
            <div style="margin-top: 5px">当前服务商：{{ getAmountName }}</div>
            <div>命中类型：：{{ activeItem?.hitType }}</div>
            <div>
              命中关键词：<el-tag
                class="right-width"
                v-for="(item, i) in hitTypeDescList"
                :key="i"
                size="mini"
                >{{ item }}</el-tag
              >
            </div>
            <div>报备行业：{{ activeItem?.name }}</div>
            <div>报备话术：{{ activeItem?.template }}</div>
          </el-card>
        </div>
      </div>
      <!-- 1.2右边对话 -->
      <div class="talkBox">
        <div v-for="(oitem, o) in translatedContent" :key="o">
          <div class="talkBox-item" v-show="oitem.channel_id == 0">
            <!--            <div class="flex-nowrap-flex-start">-->
            <!--              <div class="text-align-left">主叫</div>-->
            <!--              <SoundOutlined style="margin-left: 5px"/>-->
            <!--            </div>-->
            <div class="text-align-left">主叫</div>
            <div class="flex-nowrap-flex-start relative-box">
              <div>
                <el-avatar
                  :size="40"
                  :src="require(`@/assets/images/telA.png`)"
                ></el-avatar>
              </div>
              <div class="triangle"></div>
              <div
                class="ml-15 telA-box"
                style="max-width: 60%"
                @click="positionAudio(oitem)"
                v-html="$filter.mateKeyword(oitem?.text, hitTypeDescList)"
              ></div>
            </div>
          </div>
          <div class="talkBox-item" v-show="oitem.channel_id == 1">
            <!--            <div class="flex-nowrap-flex-end">-->
            <!--              <SoundOutlined rotate="180" style="margin-right: 5px"/>-->
            <!--              <div class="text-align-right">被叫</div>-->
            <!--            </div>-->
            <div class="text-align-right">被叫</div>
            <div class="flex-nowrap-flex-end relative-box">
              <div
                class="mr-15 telB-box"
                style="max-width: 60%"
                @click="positionAudio(oitem)"
                v-html="$filter.mateKeyword(oitem?.text, hitTypeDescList)"
              ></div>
              <div class="triangle-other"></div>
              <div>
                <el-avatar
                  :size="40"
                  :src="require(`@/assets/images/telB.png`)"
                ></el-avatar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="talkTextFixd">
        <audio
          id="audio"
          class="recordSon-audio"
          :src="activeItem?.recordUrl"
          controls="controls"
        ></audio>
        <span @click="copyMessage">复制质检记录</span>
      </div>
    </div>

    <!-- 2.底部操作 -->
    <div class="flex-nowrap-space-between mt-10">
      <div>
        <div
          v-if="
            showBottomHandle && audit && $store.getters.userType == 'system'
          "
        >
          <label
            style="
              margin-right: 10px;
              padding-left: 10px;
              padding-right: 10px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            :class="auditStatusClass"
            >{{ auditStatusDesc }}</label
          >
          <label style="margin-right: 5px">审核结果</label>
          <a-radio-group name="radioGroup" v-model:value="auditStatus">
            <a-radio :value="1">正常</a-radio>
            <a-radio :value="2">违规</a-radio>
            <a-radio :value="3">误判</a-radio>
            <a-radio :value="4">复审</a-radio>
            <a-radio :value="0">未审核</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div>
        <!-- <el-button
          v-if="showBottomHandle"
          type="primary"
          plain
          @click="copyMessage"
          >复制质检记录</el-button
        > -->
        <el-button
          v-if="showBottomHandle"
          type="primary"
          plain
          @click="handelUP"
          :loading="btnLoading"
          >上一个
        </el-button>
        <el-button
          v-if="showBottomHandle"
          type="primary"
          plain
          @click="handelNext"
          :loading="btnLoading"
          >下一个
        </el-button>
        <el-button
          v-if="
            showBottomHandle && audit && $store.getters.userType == 'system'
          "
          type="primary"
          plain
          @click="handelAutd"
          :loading="btnLoading"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确认
        </el-button>
        <el-button
          v-if="showAddWorkOrder && $store.getters.userType == 'system'"
          type="primary"
          plain
          @click="addWorkOrder"
          >添加工单</el-button
        >
        <el-button @click="handelClose">关闭</el-button>
      </div>
    </div>
    <!-- 主叫禁用弹窗 -->
    <el-dialog
      v-model="bannedUserDialog"
      title="将封禁该号码, 是否继续?"
      append-to-body
      :show-close="false"
      :destroy-on-close="true"
    >
      <el-form
        ref="formRef"
        layout="horizontal"
        :model="bannedForm"
        label-width="100px"
      >
        <el-form-item label="主叫：" class="m3-margin-bottom-m">
          {{ telADesc }}
        </el-form-item>
        <el-form-item label="服务商：" class="m3-margin-bottom-m">
          <!--          {{ rowJson.amountName }}-->
          <el-select
            v-model="bannedForm.amountId"
            placeholder="请选择服务商"
            filterable
            clearable
            :options="amountList"
            ref="select"
          >
            <el-option
              :value="item.value"
              v-for="item in amountList"
              :label="item.label"
              :key="item.value"
              >{{ item.label }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="禁用时长:" name="disableDuration">
          <el-select
            v-model="bannedForm.disableDuration"
            placeholder="请选择禁用时长"
          >
            <el-option
              v-for="(item, index) in disableDurationList"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因:" name="reason">
          <el-select
            v-model="bannedForm.reason"
            placeholder="请选择原因"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in bannedReasonList"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:" name="remark">
          <el-input
            class="m3-width-full"
            v-model="bannedForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="m3-flex m3-justify-content-end">
        <el-button plain @click="bannedUserDialog = false">取消</el-button>
        <el-button
          type="primary"
          :loading="bannedUserDialogLoading"
          @click="bannedUser"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 小号禁用弹窗    -->
    <el-dialog
      v-model="bannedUserDialog1"
      title="将封禁该号码, 是否继续?"
      append-to-body
      :show-close="false"
      :destroy-on-close="true"
    >
      <el-form
        ref="formRef"
        layout="horizontal"
        :model="bannedForm1"
        label-width="100px"
      >
        <el-form-item label="小号：" class="m3-margin-bottom-m">
          {{ activeItem.telX }}
        </el-form-item>
        <!-- <el-form-item label="服务商：" class="m3-margin-bottom-m">
          <el-select v-model="bannedForm1.amountId" placeholder="请选择服务商" filterable clearable
                     :options="amountList"
                     ref="select">
            <el-option :value="item.value" v-for="item in amountList"
                       :label="item.label"
                       :key="item.value">{{
                item.label
              }}
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="禁用时长:" name="disableDuration">
          <el-select
            v-model="bannedForm1.disableDuration"
            placeholder="请选择禁用时长"
          >
            <el-option
              v-for="(item, index) in disableDurationList"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因:" name="reason">
          <el-select
            v-model="bannedForm1.reason"
            placeholder="请选择原因"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in bannedReasonList1"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:" name="remark">
          <el-input
            class="m3-width-full"
            v-model="bannedForm1.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="m3-flex m3-justify-content-end">
        <el-button
          plain
          @click="
            (bannedUserDialog1 = false),
              (bannedForm1.disableDuration = null),
              (bannedForm1.reason = ''),
              (bannedForm1.remark = '')
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="bannedUserDialogLoading1"
          @click="bannedUser1"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import _lineService from "@/api/open/LineQualityInspections";
import { ElMessage } from "element-plus";
import moment from "moment";
import amount from "@/api/open/amount/amount";
import { SoundOutlined } from "@ant-design/icons-vue";
import industryRequest from "@/api/settings/industry";
import { httpEnv } from "@/utils/config";
import number, { voiceCheckGroup, getByCompany } from "@/api/open/privacy/number";
import { safePromise } from "@/utils/common/safePromise";
import EmptyUtil from "@/utils/common/EmptyUtil";
import { modalConfirm } from "@/utils/common/modalConfirm";
import { Item } from "ant-design-vue/lib/menu";

export default {
  components: {
    // SoundOutlined,
  },
  props: {
     tableData: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    value: {
      type: Boolean,
      default: () => false,
    },
    type: {
      default: "other", //'apiRule':api质检记录,'lineRule':线路质检记录,'other'
    },
    rowJson: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isAdminUser: {
      type: String,
      default: () => {
        return "";
      },
    },
   
    showBottomHandle: {
      //显示底部操作按钮
      type: Boolean,
      default: () => true,
    },
    showAddWorkOrder: {
      //显示底部创建工单操作按钮
      type: Boolean,
      default: () => false,
    },
    audit: {
      type: Boolean,
      default: () => true,
    },
    getParams: {
      type: Object,
      default: () => ({}),
    },
    ruleType: {
      type: Object,
      default: () => {
        {
          ("");
        }
      },
    },
      infoActive: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    amountOption: {
      type: Array,
      default: [],
    },

    createTimeBegin: {
      type: String,
      default: () => {
        return moment(
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 > 9
              ? new Date().getMonth() + 1
              : "0" + (new Date().getMonth() + 1)
          }-${
            new Date().getDate() > 9
              ? new Date().getDate()
              : "0" + new Date().getDate()
          }` + "00:00:00",
          "YYYY-MM-DD  HH:mm:ss"
        );
      },
    },
    createTimeEnd: {
      type: String,
      default: () => {
        return moment(
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 > 9
              ? new Date().getMonth() + 1
              : "0" + (new Date().getMonth() + 1)
          }-${
            new Date().getDate() > 9
              ? new Date().getDate()
              : "0" + new Date().getDate()
          }` + "00:00:00",
          "YYYY-MM-DD  HH:mm:ss"
        );
      },
    },
  },
  data() {
    return {
      clickNext:"",
      fullscreenLoading:false,
      label: [],
      loadingIcon: false,
      ruleTypeDescList: [
        {
          label: "全部",
          value: "",
          desc: "0/0",
          labelColor: "#fff0f0",
          rowClass: "",
        },
        {
          label: "特大违规",
          value: 1,
          desc: "0/0",
          labelColor: "#fa5151",
          rowClass: "rule-type-highest",
        },
        {
          label: "重大违规",
          value: 2,
          desc: "0/0",
          labelColor: "#ff8f1f",
          rowClass: "rule-type-high",
        },
        {
          label: "一般违规",
          value: 3,
          desc: "0/0",
          labelColor: "#ffc300",
          rowClass: "rule-type-mid",
        },
        {
          label: "轻微违规",
          value: 4,
          desc: "0/0",
          labelColor: "#96b97d",
          rowClass: "rule-type-low",
        },
        // {label: '无违规', value: 0, desc: '0/0', labelColor: '#00b578', rowClass: 'rule-type-none'},
      ],
      activeIndex: "",
      infractions: 0, //违规次数
      hitTypeDescList: [],
      translatedContent: [],
      // industry: '',
      // textarea1641434070934: '',
      btnLoading: false,
      auditStatus: 1, //默认正常
      riskOption: [
        { label: "高", value: 3 },
        { label: "中", value: 2 },
        { label: "低", value: 1 },
        { label: "无", value: 0 },
      ],

      disableDurationList: [
        { label: "1小时", value: 1 },
        { label: "2小时", value: 2 },
        { label: "4小时", value: 4 },
        { label: "8小时", value: 8 },
        { label: "12小时", value: 12 },
        { label: "24小时", value: 24 },
        { label: "48小时", value: 48 },
        { label: "永久", value: "" },
      ],
      bannedReasonList: [
        {
          label:
            "因拨打话务与报备话术不一致，但不涉及禁入场景，主叫被临时禁用，请联系平台客服处理。",
          value: 1,
        },
        {
          label:
            "因涉及辱骂客户或与客户对骂等场景，主叫被临时禁用，请联系平台客服处理。",
          value: 2,
        },
        {
          label:
            "因拨打话务涉及平台禁入场景，主叫已被禁用，请联系平台客服处理。",
          value: 3,
        },
        {
          label:
            "因拨打话务涉及该线路禁止提及的关键词，主叫已被禁用，请联系平台客服处理。",
          value: 4,
        },
      ],
      bannedReasonList1: [
        {
          label:
            "因拨打话务与报备话术不一致，但不涉及禁入场景，小号被临时禁用，请联系平台客服处理。",
          value: 1,
        },
        {
          label:
            "因涉及辱骂客户或与客户对骂等场景，小号被临时禁用，请联系平台客服处理。",
          value: 2,
        },
        {
          label:
            "因拨打话务涉及平台禁入场景，小号已被禁用，请联系平台客服处理。",
          value: 3,
        },
        {
          label:
            "因拨打话务涉及该线路禁止提及的关键词，小号已被禁用，请联系平台客服处理。",
          value: 4,
        },
      ],
      bannedForm: {
        amountId: "0",
        disableDuration: null, //null表示没选，’‘空字符串表示永久
        reason: "",
        remark: "",
      },
      bannedForm1: {
        amountId: "0",
        disableDuration: null, //null表示没选，’‘空字符串表示永久
        reason: "",
        remark: "",
      },
      bannedUserDialog: false,
      bannedUserDialog1: false,
      bannedUserDialogLoading: false,
      bannedUserDialogLoading1: false,
      voiceList: [],
      voiceForm: {
        telA: "",
        type: 1,
        page: 1,
        pageSize: 10,
        translatedContent: "",
      },
      listLength: false,
      options: [
        {
          label: "正常",
          value: 0,
        },
        {
          label: "违规",
          value: 1,
        },
      ],
      throttleTimeout: null,
      feigeCompany: {},
      industryName: "",
      activeItem: null,
      businessTypes: "",
      amountIdName: "",
    };
  },
  unmounted() {
    if (this.audioTimeOut) {
      clearTimeout(this.audioTimeOut);
    }
  },
  watch: {
     infoActive(newVal, oldVal) {
      console.log("infoActive 变化了:", newVal, oldVal);
      this.clickNext=newVal
    },
    rowJson(newV, preV) {
      //上一条，下一条时button loading，获取到新值后取消loading
      this.btnLoading = false;
    },
  },
  computed: {
    needHideKeyValueRoleId() {
      let env = httpEnv();
      if (env == "dev") {
        return 7;
      } else if (env == "test") {
        return 7;
      }
      return 16;
    },
    needHideKeyValue() {
      //部分关键值不能被看到（目前对部分兼职生效）：渠道隐藏、企业隐藏、主叫脱敏、被叫脱敏
      return this.$store.state.user?.roleId == this.needHideKeyValueRoleId;
      // return true;
    },
    telADesc() {
      // if (this.needHideKeyValue) {
      //   return this.rowJson?.telA && this.rowJson?.telA.length >= 11 ? this.rowJson.telA.replace(this.rowJson.telA.substring(3, 7), "****") : this.rowJson?.telA;
      // }
      return this.rowJson?.telA;
    },
    telBDesc() {
      // if (this.needHideKeyValue) {
      //   return this.rowJson?.telB && this.rowJson?.telB.length >= 11 ? this.rowJson.telB.replace(this.rowJson.telB.substring(3, 7), "****") : this.rowJson?.telB;
      // }
      return this.rowJson?.telB;
    },
    telXDesc() {
      // if (this.needHideKeyValue) {
      //   return this.rowJson?.telX && this.rowJson?.telX.length >= 11 ? this.rowJson.telX.replace(this.rowJson.telX.substring(3, 7), "****") : this.rowJson?.telX;
      // }
      return this.rowJson?.telX;
    },
    amountList() {
      let res = [{ label: "全部", value: "0" }];
      if (this.amountOption) {
        res.splice(res.length, 0, ...this.amountOption);
      }
      return res;
    },
    auditStatusDesc() {
      if (this.activeItem?.auditStatus == "1") {
        return "正常";
      } else if (this.activeItem?.auditStatus == "2") {
        return "违规";
      } else if (this.activeItem?.auditStatus == "3") {
        return "误判";
      } else if (this.activeItem?.auditStatus == "4") {
        return "复审";
      } else if (this.activeItem?.auditStatus == "0") {
        return "未审核";
      }
      return "未知";
    },
    auditStatusClass() {
      if (this.activeItem?.auditStatus == "1") {
        return "audit-status-none";
      } else if (this.activeItem?.auditStatus == "2") {
        return "audit-status-highest";
      } else if (this.activeItem?.auditStatus == "3") {
        return "audit-status-high";
      } else if (this.activeItem?.auditStatus == "4") {
        return "audit-status-mid";
      } else if (this.activeItem?.auditStatus == "0") {
        return "audit-status-low";
      }
      return "audit-status-low";
    },
    
  },
  methods: {
    auditStatusDesc1(auditStatus) {
      if (auditStatus == "1") {
        return "正常";
      } else if (auditStatus == "2") {
        return "违规";
      } else if (auditStatus == "3") {
        return "误判";
      } else if (auditStatus == "4") {
        return "复审";
      } else if (auditStatus == "0") {
        return "未审核";
      }
      return "未知";
    },
    auditStatusClass1(auditStatus) {
      if (auditStatus == "1") {
        return "audit-status-none";
      } else if (auditStatus == "2") {
        return "audit-status-highest";
      } else if (auditStatus == "3") {
        return "audit-status-high";
      } else if (auditStatus == "4") {
        return "audit-status-mid";
      } else if (auditStatus == "0") {
        return "audit-status-low";
      }
      return "audit-status-low";
    },
    industryidlist() {
      amount.industryidlist().then((res) => {
        this.label = res.data;
        this.label.forEach((item, index) => {
          if (this.feigeCompany.businessType) {
            if (item.id == this.feigeCompany.businessType) {
              this.industryName = item.name;
            } else {
              this.industryName = "";
            }
          }
        });
      });
    },

    changeInput(e) {
      this.voiceForm.telA = e;
      this.voiceForm.page = 1;
      this.listLength = false;
      this.throttledVoiceCheckGroup();
    },
    throttledVoiceCheckGroup() {
      if (this.throttleTimeout) {
        clearTimeout(this.throttleTimeout);
      }
      this.throttleTimeout = setTimeout(() => {
        this.voiceCheckGroup();
        this.$refs.numBox.scrollTop = 0;
        this.throttleTimeout = null;
      }, 1000);
    },
    changeType(e) {
      this.voiceForm.type = e;
      this.voiceForm.page = 1;
      this.voiceForm.translatedContent=''
      this.$refs.numBox.scrollTop = 0;
      this.activeIndex=0
      this.listLength = false;
      this.voiceCheckGroup();
    },
    getByCompany() {
      getByCompany({
        company: this.rowJson.feigeCompany,
      }).then((res) => {
        if (res.code == 200) {
          this.feigeCompany = res.data;
          this.industryidlist();
        }
      });
    },
    handleScroll(event) {
      const container = event.target;
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.listLength) {
        return false;
      }
      this.loadingIcon = true;
      setTimeout(() => {
        this.voiceForm.page++;
        voiceCheckGroup({
          type: this.voiceForm.type,
          telA: this.rowJson.telA,
          createTimeBegin: this.createTimeBegin,
          createTimeEnd: this.createTimeEnd,
          page: this.voiceForm.page,
          pageSize: this.voiceForm.pageSize,
          translatedContent: this.voiceForm.translatedContent,
        }).then((res) => {
          if (res.code == 200) {
            const data1 = res.data.records;
            this.voiceList = this.voiceList.concat(data1);
            if (data1.length == 0) {
              this.listLength = true;
              this.loadingIcon = false;
            }
          }
        });
        // this.loading = false;
      }, 200);
    },

    voiceCheckGroup() {
      voiceCheckGroup({
        type: this.voiceForm.type,
        telA: this.rowJson.telA,
        createTimeBegin: this.createTimeBegin,
        createTimeEnd: this.createTimeEnd,
        page: this.voiceForm.page,
        pageSize: this.voiceForm.pageSize,
        translatedContent: this.voiceForm.translatedContent,
      }).then((res) => {
        if (res.code == 200) {
          this.voiceList = res.data.records;
          if (res.data.records.length == 0) {
            this.businessTypes = "";
            this.getAmountName = "";
            this.translatedContent = [];
            this.hitTypeDescList = [];
            this.activeItem = null;
            this.loadingIcon = false;
            return;
          }
          if (this.voiceList) {
            this.activeIndex = this.voiceList[0].id;
            this.activeItem = this.voiceList[0];
            this.auditStatus=this.voiceList[0].auditStatus
            this.businessTypes = "";
            this.getAmountName = "";
            this.loadingIcon = false;
            const obj = this.amountOption.find(
              (item) => item.value == res.data.records[0].amountId
            );
            this.getAmountName = obj ? obj.label : "--";
            this.businessTypes = res.data.records[0].businessType;
            if (res.data.records[0].hitTypeDesc) {
              this.hitTypeDescList = res.data.records[0].hitTypeDesc.split(",");
            } else {
              this.hitTypeDescList = [];
            }
            if (res.data.records[0].businessType) {
              industryRequest
                .queryById({
                  id: res.data.records[0].businessType,
                })
                .then((res) => {
                  this.activeItem.template = res?.data?.template;
                  this.activeItem.name = res?.data?.name;
                });
            }
            let translatedContentSentences;
            if (this.activeItem.translatedContent) {
              translatedContentSentences = JSON.parse(
                this.activeItem.translatedContent
              ).payload?.sentences;
            }

            if (!translatedContentSentences) {
              const res = _lineService._recordService.getByCheckId({
                checkId: this.voiceList[0].id,
              });
              translatedContentSentences = JSON.parse(
                res.data.translatedContent
              ).result;
            }

            this.translatedContent = translatedContentSentences || [];
            // this.readyToCopy = `线路名称：${this.rowJson.cmV1 || '--'}\r\n机构名称：${this.rowJson.cmV2 || '--'}\r\n主叫：${
            this.readyToCopy = `线路名称：${this.getAmountName || "--"}\r\n${
              this.needHideKeyValue ? "" : "机构名称："
            }${
              this.needHideKeyValue
                ? ""
                : this.activeItem.nickName || this.activeItem.company || "--"
            }${this.needHideKeyValue ? "" : "\r\n"}主叫：${
              this.telADesc || "--"
            }\r\n被叫：${this.activeItem?.telB || "--"}\r\n通话时间：${
              this.activeItem.stratTime || "--"
            }\r\n命中类型：${
              this.activeItem.hitType || "--"
            }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
              this.activeItem.recordUrl || "--"
            }`;
          }
        }
      });
    },
    async handleClick(item, index) {
      this.activeIndex = item.id;
      this.activeItem = item;
       this.auditStatus=item.auditStatus
      const obj = this.amountOption.find(
        (items) => items.value == item.amountId
      );
      this.getAmountName = obj ? obj.label : "--";
      if (this.activeItem.businessType) {
        //行业id
        //根据行业id查找行业话术
        let res = await industryRequest.queryById({
          id: this.activeItem.businessType,
        });
        this.activeItem.template = res?.data?.template;
        this.activeItem.name = res?.data?.name;
      }
      let translatedContentSentences;
      if (this.activeItem.translatedContent) {
        translatedContentSentences = JSON.parse(
          this.activeItem.translatedContent
        ).payload?.sentences;
      }

      if (!translatedContentSentences) {
        const res = await _lineService._recordService.getByCheckId({
          checkId: item.id,
        });
        translatedContentSentences = JSON.parse(
          res.data.translatedContent
        ).result;
      }

      this.translatedContent = translatedContentSentences || [];
      // this.readyToCopy = `线路名称：${this.rowJson.cmV1 || '--'}\r\n机构名称：${this.rowJson.cmV2 || '--'}\r\n主叫：${
      this.readyToCopy = `线路名称：${this.getAmountName || "--"}\r\n${
        this.needHideKeyValue ? "" : "机构名称："
      }${
        this.needHideKeyValue
          ? ""
          : this.activeItem.nickName || this.activeItem.company || "--"
      }${this.needHideKeyValue ? "" : "\r\n"}主叫：${
        this.telADesc || "--"
      }\r\n被叫：${this.activeItem?.telB || "--"}\r\n通话时间：${
        this.activeItem.stratTime || "--"
      }\r\n命中类型：${
        this.activeItem.hitType || "--"
      }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
        this.activeItem.recordUrl || "--"
      }`;
      console.log(translatedContentSentences);
      this.hitTypeDescList = this.activeItem.hitTypeDesc?.split(",");
      console.log(this.activeItem, index);
    },
    //定位当前语音位置并播放
    positionAudio(sentence) {
      // play()开始播放音频
      // pause()暂停当前播放的音频
      // load()重新加载音频元素
      // addTextTrack()在音频中添加一个新的文本轨道
      // fastSeek()在音频播放器中指定播放时间。
      // canPlayType()检查浏览器是否可以播放指定的音频类型
      // getStartDate()返回一个新的Date对象，表示当前时间轴偏移量
      let audio = document.getElementById("audio");
      if (null != audio && sentence.begin_time) {
        audio.currentTime = sentence.begin_time / 1000; //设置从哪里开始播放
        audio.play();
        let duration = sentence.end_time - sentence.begin_time; //结束时间减去开始时间就是时长，结合setTimeout实现只播放当前一段话
        this.audioTimeOut = setTimeout(() => {
          if (null != audio) {
            audio.pause();
          }
        }, duration);
      }
    },
    async handleRuleTypeChange(value) {
      console.log("handleRuleTypeChange", value);
      try {
        await _lineService._recordService.updateVoiceRisk({
          checkId: this.rowJson.id,
          risk: value,
        });
        ElMessage({
          message: "修改成功",
          type: "success",
        });

        this.rowJson.risk = value;
        let values = { risk: this.rowJson.risk };
        // this.$emit('successDDD')
        // this.$emit('nextData', this.rowJson)
        this.handelChange(0, values);
      } catch (err) {
        console.log(err);
      }
    },
    async handleRiskChange(e) {
      let targetRisk = e.target.value;
      try {
        await _lineService._recordService.updateVoiceRisk({
          checkId: this.rowJson.id,
          risk: targetRisk,
        });
        ElMessage({
          message: "修改成功",
          type: "success",
        });

        this.rowJson.risk = targetRisk;
        // this.$emit('successDDD')
        this.$emit("nextData", this.rowJson);
      } catch (err) {
        console.log(err);
      }
    },
    async open() {
      console.log(this.rowJson, this.tableData,"00994");
      this.hitTypeDescList = [];
      let res1 = await safePromise(
        _lineService._workOrderService.getInfractionCount({
          caller: this.rowJson.telA,
        })
      );
      if (res1?.code == 200) {
        this.infractions = res1.data;
      } else {
        this.infractions = 0;
      }
      this.voiceForm.page = 1;
      this.listLength = false;
      this.voiceForm.type = 1;
      this.voiceForm.translatedContent=''
       voiceCheckGroup({
        type: this.voiceForm.type,
        telA: this.rowJson.telA,
        createTimeBegin: this.createTimeBegin,
        createTimeEnd: this.createTimeEnd,
        page: this.voiceForm.page,
        pageSize: this.voiceForm.pageSize,
        translatedContent: this.voiceForm.translatedContent,
      }).then(res=>{
            if (res.code == 200) {
          this.voiceList = res.data.records;
          if (res.data.records.length == 0) {
            this.businessTypes = "";
            this.getAmountName = "";
            this.translatedContent = [];
            this.hitTypeDescList = [];
            this.activeItem = null;
            this.loadingIcon = false;
            return;
          }
          if (this.voiceList) {
            this.activeIndex = this.voiceList[0].id;
            this.activeItem = this.voiceList[0];
            this.auditStatus=this.voiceList[0].auditStatus
            this.businessTypes = "";
            this.getAmountName = "";
            this.loadingIcon = false;
            const obj = this.amountOption.find(
              (item) => item.value == res.data.records[0].amountId
            );
            this.getAmountName = obj ? obj.label : "--";
            this.businessTypes = res.data.records[0].businessType;
            if (res.data.records[0].hitTypeDesc) {
              this.hitTypeDescList = res.data.records[0].hitTypeDesc.split(",");
            } else {
              this.hitTypeDescList = [];
            }
            if (res.data.records[0].businessType) {
              industryRequest
                .queryById({
                  id: res.data.records[0].businessType,
                })
                .then((res) => {
                  this.activeItem.template = res?.data?.template;
                  this.activeItem.name = res?.data?.name;
                });
            }
            let translatedContentSentences;
            if (this.activeItem.translatedContent) {
              translatedContentSentences = JSON.parse(
                this.activeItem.translatedContent
              ).payload?.sentences;
            }

            if (!translatedContentSentences) {
              const res = _lineService._recordService.getByCheckId({
                checkId: this.voiceList[0].id,
              });
              translatedContentSentences = JSON.parse(
                res.data.translatedContent
              ).result;
            }

            this.translatedContent = translatedContentSentences || [];
            // this.readyToCopy = `线路名称：${this.rowJson.cmV1 || '--'}\r\n机构名称：${this.rowJson.cmV2 || '--'}\r\n主叫：${
            this.readyToCopy = `线路名称：${this.getAmountName || "--"}\r\n${
              this.needHideKeyValue ? "" : "机构名称："
            }${
              this.needHideKeyValue
                ? ""
                : this.activeItem.nickName || this.activeItem.company || "--"
            }${this.needHideKeyValue ? "" : "\r\n"}主叫：${
              this.telADesc || "--"
            }\r\n被叫：${this.activeItem?.telB || "--"}\r\n通话时间：${
              this.activeItem.stratTime || "--"
            }\r\n命中类型：${
              this.activeItem.hitType || "--"
            }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
              this.activeItem.recordUrl || "--"
            }`;

               console.log(this.activeItem, "77");
          }
        }
      
      })
      this.getByCompany();
    
   
       return
      if (this.rowJson.risk == 1 || this.rowJson.risk == 2) {
        this.bannedForm.amountId = "0";
      } else {
        this.bannedForm.amountId = this.rowJson.amountId
          ? `${this.rowJson.amountId}`
          : "0";
      }
    

      let translatedContentSentences;
      if (this.activeItem?.translatedContent) {
        translatedContentSentences = JSON.parse(
          this.activeItem?.translatedContent
        ).payload?.sentences;
      }
          
      if (!translatedContentSentences) {
        if ( this.voiceList[0].id) {
          const res = await _lineService._recordService.getByCheckId({
          checkId: this.voiceList[0].id,
        });
        translatedContentSentences = JSON.parse(
          res.data.translatedContent
        ).result;
        }
        
        
      }
      this.translatedContent = translatedContentSentences || [];
      // this.readyToCopy = `线路名称：${this.rowJson.cmV1 || '--'}\r\n机构名称：${this.rowJson.cmV2 || '--'}\r\n主叫：${
      this.readyToCopy = `线路名称：${this.getAmountName || "--"}\r\n${
        this.needHideKeyValue ? "" : "机构名称："
      }${
        this.needHideKeyValue
          ? ""
          : this.activeItem.nickName || this.activeItem.company || "--"
      }${this.needHideKeyValue ? "" : "\r\n"}主叫：${
        this.activeItem || "--"
      }\r\n被叫：${this.activeItem?.telB || "--"}\r\n通话时间：${
        this.activeItem.stratTime || "--"
      }\r\n命中类型：${
        this.activeItem.hitType || "--"
      }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
        this.activeItem.recordUrl || "--"
      }`;
    },
    async bannedUser() {
      // this.$confirm('将封禁该号码, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(async () => {

      let reason = "";
      if (null != this.bannedForm.reason && this.bannedForm.reason.length > 0) {
        reason = `违规提醒：主叫${this.rowJson.telA}-被叫${this.rowJson.telB}，${this.bannedForm.reason}`;
      }
      if (null == this.bannedForm.disableDuration) {
        return ElMessage({
          message: "请选择禁用时间",
          type: "error",
        });
      }
      this.bannedUserDialogLoading = true;
      let params = {
        telA: this.rowJson.telA,
        checkId: this.rowJson.id,
        amountId: this.bannedForm.amountId,
        remark: this.bannedForm.remark,
        disableDuration: this.bannedForm.disableDuration,
        reason: reason,
      };
      let res = await safePromise(
        _lineService._recordService.addBlackA(params)
      );
      if (res?.code == 200) {
        this.rowJson.telADisabled = 1;
        ElMessage({
          message: "号码封禁成功！",
          type: "success",
        });
      }
      this.bannedUserDialogLoading = false;
      this.bannedUserDialog = false;
      // })
    },

    bannedUser1() {
      let params = {
        number: this.activeItem.telX,
        remark: this.bannedForm1.remark,
        disableTime: this.bannedForm1.disableDuration,
        disableReason: this.bannedForm1.reason,
      };
      if (this.bannedForm1.disableDuration == null) {
        return ElMessage({
          message: "请选择禁用时间",
          type: "error",
        });
      }
      this.bannedUserDialogLoading1 = true;
      industryRequest.addDisableNumber(params).then((res) => {
        if (res.code == 200) {
          // this.rowJson.telXDisabled = 1;
          ElMessage({
            message: "号码封禁成功！",
            type: "success",
          });
               const activeItem = this.voiceList.find(item => item.id === this.activeIndex);
      if (activeItem) {
        this.voiceList.forEach((obj) => {
          if (obj.telX === activeItem.telX) {
            obj.telXDisabled = 1;
          }
        });
      }
          this.bannedUserDialogLoading1 = false;
          this.bannedUserDialog1 = false;
        
        } else {
          ElMessage({
            message: res.message || res.msg,
            type: "error",
          });
        }
      });
      this.bannedUserDialogLoading1 = false;
    },
    bannedUserOther(item, index) {
      this.$confirm("将封禁该号码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await _lineService._recordService.blackNumberAdd({
          blackNumberDtoList: [
            {
              number: item.telB,
              checkId: item.id,
            },
          ],
        });
        this.activeIndex = item.id;
        this.voiceList.forEach((obj) => {
          if (obj.telB === item.telB) {
            obj.telBDisabled = 1;
          }
        });

        ElMessage({
          message: "号码封禁成功！",
          type: "success",
        });
      });
    },
    bannedUserOther3(item, index) {
      this.bannedUserDialog1 = true;
      this.bannedForm1.disableDuration = null;
      this.bannedForm1.reason = "";
      this.bannedForm1.remark = "";
      this.activeIndex = item.id;
        // this.voiceList.forEach((obj) => {
        //   if (obj.telX === item.telX) {
        //     obj.telXDisabled = 1;
        //   }
        // });
    },
    copyMessage() {
      if (this.voiceList.length == 0) {
        return ElMessage({
          message: "复制失败,暂无数据",
          type: "error",
        });
      }
      let input = document.createElement("textarea");
      input.style.opacity = "0";
      input.style.position = "absolute";
      input.style.zIndex = "-1";
      document.body.append(input);
      this.readyToCopy = this.readyToCopy.replace(
        "[object Object]",
        this.telADesc
      );
      input.value = this.readyToCopy;
      console.log(input.value);
      input.select();
      let result = document.execCommand("Copy");
      if (result) {
        ElMessage({
          message: "复制成功",
          type: "success",
        });
      } else {
        ElMessage({
          message: "复制失败",
          type: "error",
        });
      }
      setTimeout(function () {
        document.body.removeChild(input);
      }, 50);
    },
    handelClose() {
      this.$emit("success");
    },
    addWorkOrder() {
         if (this.voiceList.length == 0) {
        return ElMessage({
          message: "暂无数据",
          type: "error",
        });
      }
      this.$emit("addWorkOrder",this.activeItem);
      this.$emit("success");
    },
    async handelNext() {
      this.$emit('change-row', 'next');
      //  console.log(this.infoActive+1,'123');
      // if (this.infoActive+1==this.tableData) {
      //   return  ElMessage({
      //     message: "已是该页最后一条",
      //     type: "error",
      //   });
      // }
      this.btnLoading = true;
      //接口调整，不再从接口取数据，直接从列表拿现有数据
      this.hitTypeDescList = [];
      this.handelChange(1, null);
      // this.$emit('nextData', 1, values)
    },
    async handelUP() {
      this.$emit('change-row', 'previous');
      console.log(this.infoActive,'321');
      // if (this.clickNext==0) {
      //   return  ElMessage({
      //     message: "已是第一条",
      //     type: "error",
      //   });
      // }
      this.btnLoading = true;
      //接口调整，不再从接口取数据，直接从列表拿现有数据
      // this.$emit('nextData', -1, values)
      this.handelChange(-1, null);
    },
    /**
     *
     * @param values 当前更新的值
     * @param page 1：下一条数据；0：当前不动，只是更新值；-1：上一条数据
     * @returns {Promise<void>}
     */
    async handelChange(page, values) {
      //接口调整，不再从接口取数据，直接从列表拿现有数据
      //与 record页面中handleRowClick方法兼容，中间三个参数给空值
      this.$emit("nextData", page, null, null, null, values);
      
    },
    async updateVoiceRisk(risk) {
      this.btnLoading = true;
      try {
        await _lineService._recordService.updateVoiceRisk({
          checkId: this.rowJson.id,
          risk: risk,
        });
        this.rowJson.risk = risk;
        ElMessage({
          message: "修改成功",
          type: "success",
        });
        this.$emit("successDDD");
      } catch (err) {}
      this.btnLoading = false;
    },
    async handelAutd() {
      if (this.voiceList.length == 0) {
        return ElMessage({
          message: "暂无数据",
          type: "error",
        });
      }
      this.btnLoading = true;
      try {
        if (this.isAdminUser === "web" || this.ruleType === "api") {
          await _lineService._recordService.getVoiceUserCheck({
            checkId: this.activeItem.id,
            auditStatus: this.auditStatus,
            // auditStatus: this.rowJson.auditStatus
          });
        } else {
          await _lineService._recordService.getVoiceCheck({
            checkId: this.activeItem.id,
            auditStatus: this.auditStatus,
          });
        }
      //  this.activeItem.auditStatus = this.auditStatus; //
        // this.auditStatus = 1; //重置为正常
      this.fullscreenLoading = true;
       this.voiceForm.page = 1;
       this.listLength = false;
       this.voiceForm.translatedContent=''
       this.$refs.numBox.scrollTop = 0;
       setTimeout(() => {
         this.voiceCheckGroup()
         ElMessage({
          message: "审核成功",
          type: "success",
        });
         this.fullscreenLoading = false;
       }, 1000);
        
        // this.$emit('successDDD')
         let values = { auditStatus: this.activeItem.auditStatus };
         this.handelChange(1, values);
      } catch (err) {}
      this.btnLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.flex-nowrap-flex-start {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-nowrap-space-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.left-title {
  width: 120px;
  height: 45px;
  padding: 10px 0 10px 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}

.left-text {
  width: 207px;
  height: 45px;
  color: #333333;
  padding: 10px 0 10px 15px;
  border: 1px solid #ebebeb;
  border-left: none;
}

.left-title-mid {
  width: 120px;
  height: 45px;
  padding: 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}

.left-text-mid {
  width: 207px;
  height: 45px;
  padding: 2px;
  color: #333333;
  border: 1px solid #ebebeb;
  border-left: none;
}

.left-title-other {
  width: 120px;
  height: 185px;
  line-height: 185px;
  padding-left: 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}

.left-text-other {
  width: 207px;
  height: 185px;
  padding: 15px;
  color: #333333;
  border: 1px solid #ebebeb;
  border-left: none;
}

.bottom-box {
  height: 45px;
  border: 1px solid #ebebeb;
}

.recordSon-audio {
  width: 95%;
  height: 30px;
}

.talkBox {
  padding: 10px;
  width: 100%;
  overflow-y: overlay;
  height: 720px;
  background: #f3f3f3;
}

.float-right {
  float: right;
}

.text-align-left {
  padding-left: 65px;
  text-align: left;
}

.text-align-right {
  padding-right: 65px;
  text-align: right;
}

.telA-box {
  padding: 8px;
  background: #fff;
  border-radius: 6px;
}

.telB-box {
  padding: 8px;
  background: #9eea6a;
  border-radius: 6px;
}

.relative-box {
  position: relative;
}

.triangle {
  position: absolute;
  transform: rotate(-90deg);
  top: 39%;
  left: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #ffff transparent;
  width: 0px;
  height: 0px;
}

.triangle-other {
  position: absolute;
  transform: rotate(90deg);
  top: 39%;
  right: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #9eea6a transparent;
  width: 0px;
  height: 0px;
}

.talkBox-item {
  padding: 2px 0;
}

.height-min {
  /* height: 500px; */
  overflow-y: overlay;
}

.right-width {
  margin-right: 10px;
  margin-bottom: 8px;
}

.overflow-y {
  overflow-y: overlay;
}

::v-deep(.el-table .cell) {
  line-height: 20px;
}

.flex-nowrap-start {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}

.voice-text {
  .voice-text-tooltip {
    font-size: 0.75rem;
  }

  .keywords {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.sentence {
  background-color: #95ec69;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 7px;
  color: #000000;
}

//<a-radio :value="1">正常</a-radio>
//<a-radio :value="2">违规</a-radio>
//<a-radio :value="3">误判</a-radio>
//<a-radio :value="4">复审</a-radio>
//<a-radio :value="0">未审核</a-radio>

.audit-status-highest {
  border-radius: 4px;
  background: #fa5151;
  color: #ffffff;
}

.audit-status-high {
  border-radius: 4px;
  background: #ff8f1f;
  color: #ffffff;
}

.audit-status-mid {
  border-radius: 4px;
  background: #ffc300;
  color: #ffffff;
}

.audit-status-low {
  border-radius: 4px;
  background: #96b97d;
  color: #ffffff;
}

.audit-status-none {
  border-radius: 4px;
  background: #00b578;
  color: #ffffff;
}
.telAbox {
  width: 100%;
}
.flex1 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.flex1Text1 {
  width: 70%;
}
.flex1Text1 div:nth-of-type(1) {
  font-size: 20px;
  font-weight: 500;
}
.flex1Text2 {
  width: 30%;
  text-align: center;
}
.flex1Text2 div:nth-of-type(1) {
  font-size: 20px;
  font-weight: 700;
  color: rgb(118, 134, 220);
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.clearfix {
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}
.headerTelA {
  font-size: 18px;
  color: rgb(118, 134, 220);
}
.numBox {
  margin-top: 10px;
  height: 450px;
  // border: 1px solid red;
  overflow: hidden;
  overflow-y: scroll;
}
.active {
  background-color: rgb(244, 245, 252);
}
.flex-spb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-spb1 {
  cursor: pointer;
  margin-top: 10px;
  // height: 50px;
}
.row {
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}
// .flexs{
//   display: flex;
//   justify-content: space-between;
// }
.talkTextFixd {
  padding: 5px;
  position: absolute;
  z-index: 999999;
  right: 4%;
  bottom: 3%;
  width: 450px;
  height: 40px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.talkTextFixd span {
  width: 110px;
  color: rgb(96, 174, 255);
  margin-left: 10px;
  cursor: pointer;
}
.secondBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
